export default {
  public: [],
  member: [],
  store: [
    // 非liff模式登入後redirect頁面
    {
      path: "store-oauth-return/line",
      name: "store-line-login-return",
      meta: { public: true, allowedRoles: [] },
      component: () => import("@/modules/store/views/storeLineLoginReturn.vue"),
    },
    {
      path: "store/check",
      name: "store-check",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/checkFlow.vue"),
    },
    {
      path: "store/notify-invite",
      name: "store-notify-invite",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/store/views/notifyPage/notifyInvite.vue"),
    },
    {
      path: "store/reject",
      name: "store-reject",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/notifyPage/reject.vue"),
    },
    // 新增我的商家基本資料
    {
      path: "store/register",
      name: "store-register",
      meta: {
        formMode: "update", //為了要執行read api
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/storeRegister.vue"),
    },
    // 編輯我的商家資訊
    {
      path: "store/info",
      name: "store-info",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/storeInfo.vue"),
    },
    {
      path: "store/join/:storeId",
      name: "store-join",
      meta: {
        formMode: "update",
        allowedRoles: ["signer"],
      },
      component: () => import("@/modules/store/views/storeJoin.vue"),
    },
    {
      path: "store-request",
      name: "store-request",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/store/views/notifyPage/storeInRequest.vue"),
    },
    // 合作供應商列表
    {
      path: "store/cooperation/list",
      name: "store-cooperation-list",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/cooperationList.vue"),
    },
    // 合作供應商內頁
    {
      path: "store/cooperation/:cooperateProviderId",
      name: "store-cooperation-info",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/cooperationInfo.vue"),
    },
    // // 取消合作
    // {
    //   path: 'store/cooperation/cancel',
    //   name: 'store-cooperation-cancel',
    //   component: () => import('@/modules/store/views/notifyPage/cancel.vue')
    // },
    // 商品列表
    {
      path: "product/list",
      name: "store-product-list",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/productList.vue"),
    },
    // 商品內頁
    {
      path: "product/:productId",
      name: "store-product-detail",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/store/views/productDetail/productDetail.vue"),
    },
    // 購物車
    {
      path: "cart",
      name: "cart",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/cart/index.vue"),
    },
    {
      path: "atm-payment",
      name: "atm-payment",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/atmPayment.vue"),
    },
    // 訂單記錄
    {
      path: "orders",
      name: "store-orders",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/orders/orderList.vue"),
    },
    // 全供應商訂單記錄
    // {
    //   path: "orders-all-providers",
    //   name: "store-orders-all-providers",
    //   meta: {
    //     allowedRoles: [],
    //   },
    //   component: () =>
    //     import("@/modules/store/views/orders/orderListAllProviders.vue"),
    // },
    // 訂單記錄 訂單內頁
    {
      path: "orders/:orderId",
      name: "store-order-detail",
      meta: {
        allowedRoles: ["signer"],
      },
      component: () => import("@/modules/store/views/orderDetail/index.vue"),
    },
    {
      path: "store/order-adjustment-record/:orderId",
      name: "store-order-adjustment-record",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/orderDetail/OrderAdjustmentRecord.vue"),
    },
    // 退貨記錄 內頁
    {
      path: "back-orders/:orderId",
      name: "store-back-order-detail",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/store/views/orderBackDetail/index.vue"),
    },
    // 退貨申請
    {
      path: "orders/:orderId/applyBack",
      name: "store-apply-order",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/orderBack/index.vue"),
    },
    // 最愛清單
    {
      path: "favorite",
      name: "store-favorite",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/store/views/favorite/favorite.vue"),
    },
    // 報表
    // {
    //   path: "report/list",
    //   name: "store-report-list",
    //   meta: {
    //     allowedRoles: [],
    //   },
    //   component: () => import("@/modules/store/views/report/list/index.vue"),
    // },
    // 請款總攬
    // {
    //   path: "apply-pay/overview",
    //   name: "store-apply-pay-overview",
    //   meta: {
    //     allowedRoles: [],
    //   },
    //   component: () =>
    //     import("@/modules/store/views/applyPay/list/applyPayOverview.vue"),
    // },
    {
      path: "provider/:routeProviderId/apply-pay/:id",
      name: "store-apply-pay-detail",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/store/views/applyPay/detail/applyPayDetail.vue"),
    },
    // 切換商店
    // {
    //   path: "identity/switch",
    //   name: "store-switch-identity",
    //   meta: {
    //     allowedRoles: ["signer"],
    //   },
    //   component: () => import("@/modules/store/views/storeSwitchIdentity.vue"),
    // },
    // 綁定 Line Notify 頁面
    {
      path: "line-notify-store/relation",
      name: "line-notify-store-relation",
      meta: {
        allowedRoles: ["signer"],
      },
      component: () =>
        import("@/modules/store/views/line/lineNotifyRelation.vue"),
    },
    {
      path: "download/excel",
      name: "download-excel",
      meta: { public: true, allowedRoles: [] },
      component: () => import("@/modules/base/views/download/excel.vue"),
    },
    {
      path: "service-terms",
      name: "service-terms",
      meta: { public: true, allowedRoles: [] },
      component: () => import("@/modules/store/views/terms/serviceTerms.vue"),
    },
    {
      path: "privacy-terms",
      name: "privacy-terms",
      meta: { public: true, allowedRoles: [] },
      component: () => import("@/modules/store/views/terms/privacyTerms.vue"),
    },
    {
      path: "liff-demo",
      name: "liff-demo",
      meta: { public: true, allowedRoles: [] },
      component: () => import("@/modules/base/views/liff-demo.vue"),
    },
  ],
};
